import React, { FormEvent, ChangeEvent, useRef, useState } from 'react';
import TSL_Logo from './TSL_Logo.svg';
import './App.css';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha'
import {IconContext} from "react-icons";
import {AiOutlineInstagram} from "react-icons/ai";

type FormState = {
	email: string;
	name: string;
	photo: any;
	description: string;
	qty: string;
	type: string
	job: string
	message: string;
}

type ServiceMessage = {
	class: string;
	text: string;
}

function App() {
	const formID = 'F4bIu7y9'
	const formSparkURL = `https://submit-form.com/${formID}`;
	const recaptchaKey = '6LeoGbEeAAAAAD2NDXP59uvsUnqYt8u47HbdJ3WW';
	const recaptchaRef = useRef<any>();

	const initialFormState = {
		email: '',
		name: '',
		photo: '',
		qty: '',
		type: '',
		job: '',
		message: '',
		description: '',
	}

	const [formState, setFormState] = useState<FormState>(initialFormState);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [message, setMessage] = useState<ServiceMessage>();
	const [recaptchaToken, setRecaptchaToken] = useState<string>();



	const submitForm = async (event: FormEvent) => {
		event.preventDefault();
		setSubmitting(true);
		await postSubmission()
		setSubmitting(false);
	};

	const postSubmission = async () => {
		const payload = {
			...formState,
			'g-recaptcha-response': recaptchaToken
		};

		try {
			const result = await axios.post(formSparkURL, payload)
			console.log(result)
			setMessage({
				class: 'bg-green-500',
				text: 'Thanks, someone will be in touch shortly'
			});
			setFormState(initialFormState);
			recaptchaRef.current.reset();
		} catch(error) {
			console.log(error)
			setMessage({
				class: 'bg-red-500',
				text: 'Sorrry, there was a problem. Please try again and make sure you complete the reCAPTCHA. Please email alex@topshelflaxbreauxs.com if problems continue to occur.'
			})
		}
	}

	const updateFormControl = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { id, value } = event.target
		const formkey = id as keyof FormState;
		const updatedFormState = { ...formState };
		updatedFormState[formkey] = value;
		setFormState(updatedFormState)
	}

	const updateRecaptchaToken = (token: string | null) => {
		setRecaptchaToken(token as string);
	}

  return (
	  
   	<div className='w-auto h-full flex justify flex-col'>
		<div className='sm:w-2/3 w-full m-auto p-8 shadow-lg'>
			<h1 className='text-4xl font-bold flex items-center'>
				<img src={TSL_Logo} alt='TSL Logo' className='h-9 p-1'/>
				<span>TSL Printing Co.</span>
			</h1>
			<h2 className='text-2xl flex items-center'>Custom Apparel & Graphics Inquiry</h2>
			{message && <div className={`my-4 text-white w-full p-4 ${message.class}`}>{message.text}</div>}
			<form className='flex flex-col' onSubmit={submitForm}>
				<div className='my-w flex flex-col'>
					<label htmlFor='name'>Name</label>
					<input
						required
						onChange={updateFormControl} 
						type='text' 
						placeholder='John Doe'
						className='border-2 p-2' 
						id='name' 
						value={formState.name}/>
				</div>
				<div className='my-w flex flex-col'>
					<label htmlFor='email'>Email</label>
					<input
						required
						onChange={updateFormControl} 
						type='text'
						placeholder='john@google.com'
						className='border-2 p-2' 
						id='email' 
						value={formState.email}/>
				</div>
				<div className='my-w flex flex-col'>
					<label htmlFor='job'>Job Type</label>
					<input
						required
						onChange={updateFormControl} 
						type='string'
						placeholder='Screen Printing, Embroidery, or Graphic Design' 
						className='border-2 p-2' 
						id='job' 
						value={formState.job}/>
				</div>
				<div className='my-w flex flex-col'>
					<label htmlFor='description'>Artwork Description</label>
					<input
						required
						onChange={updateFormControl} 
						type='text'
						placeholder='Typescript, 2 color left chest, 1 color back...'
						className='border-2 p-2' 
						id='description' 
						value={formState.description}/>
				</div>
				<div className='my-w flex flex-col'>
					<label htmlFor='photo'>Artwork Upload</label>
					<input
						type="hidden"
						id="photo"
						name="photo"
						// eslint-disable-next-line jsx-a11y/aria-role
						role="uploadcare-uploader"
						data-multiple="true"
						data-public-key="eea7dade63a3c13d5067"
						onChange={updateFormControl} 
						className='border-2 p-2' 
						value={formState.photo}
						/>
				</div>
				<div className='my-w flex flex-col'>
					<label htmlFor='qty'>Number of Items (Minimum 25)</label>
					<input
						required
						onChange={updateFormControl} 
						type='number' 
						placeholder='50'
						className='border-2 p-2' 
						id='qty' 
						value={formState.qty}/>
				</div>
				<div className='my-w flex flex-col'>
					<label htmlFor='type'>Type of Apparel</label>
					<input
						required
						onChange={updateFormControl} 
						type='string'
						placeholder='Shirts, Hoodies, Shorts...' 
						className='border-2 p-2' 
						id='type' 
						value={formState.type}/>
				</div>
				<div className='my-w flex flex-col'>
					<label htmlFor='message'>Job Details</label>
					<input
						required
						onChange={updateFormControl} 
						type='text'
						placeholder='Add additional comments'
						className='border-2 p-2' 
						id='message' 
						value={formState.message}/>
				</div>
				<div className='my-w p-2 flex justify-center sm:flex-col'>
					<ReCAPTCHA
						ref={recaptchaRef}
						sitekey={recaptchaKey}
						onChange={updateRecaptchaToken}
					/>
				</div>
				<button disabled={submitting} className='my-2 bg-blue-700 text-white w-full p-2 hover:bg-blue-900 transition-colors duration-200'>{submitting ? 'Submitting...' : 'Submit'}</button>
				<div className='flex justify-center'>
					<IconContext.Provider value={{color: "4169e1", size: "2rem"}} >
							<a href="https://www.instagram.com/tslprintingco/">
							<AiOutlineInstagram/>
							</a>
					</IconContext.Provider>
				</div>
				<div className='text-center p-1'>
					<label className='text-slate-600'>©2022 by TSL Printing Co.</label>
				</div>
			</form>
	   </div>
   </div>
  );
}

export default App;
